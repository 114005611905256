<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <awesome-table
                id="fedex-queues"
                :labels="labels"
                :items="orderProductColorShipments"
                :options="{
                    addButton: false,
                    sort: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.factory_data`]="{ item }">
                    <a
                        :href="`${appUrl}/factory-data/${item.orderProductColor.id}`"
                        target="_blank"
                        class="btn btn-xs btn-blue"
                        @click.stop
                    >
                        Factory Data
                    </a>
                </template>
                <template #[`items.fedex_errors`]="{ item }">
                    <div v-if="item.fedex_errors.length">
                        <span
                            v-for="(error, index) in item.fedex_errors"
                            :key="index"
                        >
                            {{ error.message }}
                            <br />
                        </span>
                    </div>
                    <span v-else>
                        Successfuly updated
                    </span>
                </template>
                <template #[`items.status`]="{ value }">
                    <span :class="getBadgeClass(value)">
                        {{ value }}
                    </span>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';
export default {
    page: {
        title: 'Fedex Queues',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Fedex Queues',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Fedex Queues',
                    active: true
                }
            ],
            labels: [
                {
                    text: 'Order Number',
                    value: 'orderProductColor.order.order_number',
                    sortable: false
                },
                {
                    text: 'Batch Id',
                    value: 'batch.batch_number'
                },
                {
                    text: 'Factory Data',
                    value: 'factory_data',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Response Message',
                    value: 'fedex_errors',
                    filterable: false
                },
                {
                    value: 'status',
                    type: 'enum',
                    enumOptions: ['COMPLETED', 'FAILED']
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                }
            ],
            orderProductColorShipments: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false,
            appUrl: config.url
        };
    },

    async created() {
        this.fetchOrderProductColorShipments();
    },

    methods: {
        ...mapActions({
            getOrderProductColorShipments: 'orderProductColorShipments/index'
        }),

        async fetchOrderProductColorShipments() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const {
                    rows,
                    count
                } = await this.getOrderProductColorShipments(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.orderProductColorShipments = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };
            this.fetchOrderProductColorShipments();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;
            this.fetchOrderProductColorShipments();
        },

        getBadgeClass(value) {
            let color = 'success';

            if (value === 'FAILED') {
                color = 'danger';
            }

            return `badge badge-${color}`;
        }
    }
};
</script>

<style lang="scss">
.vs__dropdown-menu {
    min-width: 200px;
}
</style>
