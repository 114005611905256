var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('awesome-table',{attrs:{"id":"fedex-queues","labels":_vm.labels,"items":_vm.orderProductColorShipments,"options":{
                addButton: false,
                sort: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.factory_data",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"btn btn-xs btn-blue",attrs:{"href":(_vm.appUrl + "/factory-data/" + (item.orderProductColor.id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    Factory Data\n                ")])]}},{key:"items.fedex_errors",fn:function(ref){
            var item = ref.item;
return [(item.fedex_errors.length)?_c('div',_vm._l((item.fedex_errors),function(error,index){return _c('span',{key:index},[_vm._v("\n                        "+_vm._s(error.message)+"\n                        "),_c('br')])}),0):_c('span',[_vm._v("\n                    Successfuly updated\n                ")])]}},{key:"items.status",fn:function(ref){
            var value = ref.value;
return [_c('span',{class:_vm.getBadgeClass(value)},[_vm._v("\n                    "+_vm._s(value)+"\n                ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }